.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

}

.modal-main {
    position: fixed;
    background: transparent;
    width: 100%;
    height: 80%;
    top: 45%;
    left: 87%;
    padding: 20px 0;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.modal-main1 {
    position: fixed;
    background: transparent !important;
    width: 100%;
    height: 100%;
    top: 55%;
    left: 85%;
    padding: 20px 0;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.display-block {
    display: block;
    transition: ease-in;
    -webkit-transition: ease-in;
    -moz-transition: ease-in;
    -ms-transition: ease-in;
    -o-transition: ease-in;
}

.display-none {
    display: none;
    /*transition: ease-in-out(15);*/
    /*-webkit-transition: ease-in-out(15);*/
    /*-moz-transition: ease-in-out(15);*/
    /*-ms-transition: ease-in-out(15);*/
    /*-o-transition: ease-in-out(15);*/
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: transparent;
    background-clip: padding-box;
    border: none solid rgba(0, 0, 0, 0.2);
    border-radius: 0.267rem;
    outline: 0;
}

.modal-content1 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff70;
    background-clip: padding-box;
    border: none solid rgba(0, 0, 0, 0.2);
    border-radius: 1.267rem;
    outline: 0;
}

@media (min-width: 901px) and (max-width: 1200px) {
    .modal-main {
        position: fixed;
        background: transparent;
        width: 100% !important;
        height: 50%;
        top: 30%;
        left: 70%;
        padding: 20px 0;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }

    .modal-main1 {
        position: fixed;
        background: transparent !important;
        width: 100% !important;
        height: 100%;
        top: 55%;
        left: 72%;
        padding: 20px 0;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }
}

@media (max-width: 900px) {
    .modal-main {
        position: fixed;
        background: transparent;
        width: 100% !important;
        height: 50%;
        top: 30%;
        left: 50%;
        padding: 20px 0;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }

    .modal-main1 {
        position: fixed;
        background: transparent !important;
        width: 100% !important;
        height: 100%;
        top: 50%;
        left: 50%;
        padding: 20px 0;
        transform: translate(-50%, -50%);
        border-radius: 10px;
    }
}
